import React, { useEffect } from "react";
import { render } from "storyblok-rich-text-react-renderer";

const Article = ({content}) => {


    useEffect(() => {
        const comp = document.querySelector('section')
        const main = comp.closest('main')
        main.classList.add('nav-dark')
    }, [])

  return (
    <section className="section-container mt-[8rem] lg:mt-[13rem]">
        <div className="grid lg:grid-cols-2 gap-10">
            <div className="max-w-[25.75rem]">
                <h1 className="text-2xl font-medium">{content.headline}</h1>
            </div>
            <div className="space-y-6">
                {render(content.article_html)}
            </div>
            <div className="lg:col-span-2 my-6 lg:mt-16 lg:mb-5">
                <img src={content.image.filename} className="w-full h-full aspect-video object-cover" />
            </div>
        </div>
     </section>
  );
};

export default Article;