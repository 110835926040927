import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import useStoryblok from "../lib/storyblok";
import { sbEditable } from "@storyblok/storyblok-editable";
import DynamicComponent from "../components/dynamicComponent";
import { Helmet } from "react-helmet";
import Article from "components/Article";

const DoNotIndex = () => {
  return <Helmet robots={`noindex`} />;
};

function StoryblokEntry({ data, location }) {
  if (
    data.storyblokEntry.full_slug == `footer` ||
    data.storyblokEntry.full_slug == `header`
  ) {
    return DoNotIndex;
  }

  let story = data.storyblokEntry;

  story = useStoryblok(story, location);

  const components = story.content.blocks?.map((blok) => {
    return <DynamicComponent blok={blok} key={blok._uid} />;
  });

  const blogBlocks =
    story.content.component === "blog" ? (
      <Article content={story.content} />
    ) : null;

  const isBlog = story.content.component === "blog" ? true : false;

  const seoTitle = story.content.component === "blog" ? story.content.headline : story.content.seo_title || "";
  const seoDescription = story.content.component === "blog" ? `${story.content.summary}...` : story.content.seo_description || "";
  const seoImage = story.content.component === "blog" ? story.content.image.filename : story.content.seo_image?.filename || "";

  return (
    <Layout {...sbEditable(story.content)} isBlog={isBlog}>
      <SEO
        keywords={[`WT Capital`]}
        title={seoTitle || ""}
        description={seoDescription || ""}
        socialSharing={seoImage || ""}
      />
      {components}
      {blogBlocks}
    </Layout>
  );
}

export const query = graphql`
  query ($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      id
      name
      full_slug
      content
    }
  }
`;

export default StoryblokEntry;
